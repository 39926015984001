@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&family=Inter:wght@400;500;600;700&family=Space+Grotesk:wght@400;500;600&display=swap");

/* Theme */

:root {
  --orange: #ff4f40;
  --indigo: #635dff;
  --white: #fff;
  --light-aluminium: #eaecee;
  --aluminium: #bdc4cf;
  --dark-aluminium: #2a2e35;
  --black: #000000;
  --violet: #CC6EF2;
  --yellow: #ebca40;
  --mandarine: #ff7f38;
  --pink: #ff44dd;
  --blue: #3885ff;
  --aqua: #3ec6eb;
  --emerald: #1bc99f;

  --yellow-mandarine-gradient: linear-gradient(
    153.07deg,
    var(--yellow) -2.47%,
    var(--mandarine) 102.78%
  );

  --mandarine-orange-gradient: linear-gradient(
    153.07deg,
    var(--mandarine) -2.47%,
    var(--orange) 102.78%
  );

  --pink-yellow-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--yellow) 102.78%
  );

  --pink-indigo-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--indigo) 102.78%
  );

  --indigo-aqua-gradient: linear-gradient(
    153.07deg,
    var(--indigo) -2.47%,
    var(--aqua) 102.78%
  );

  --blue-aqua-gradient: linear-gradient(
    153.07deg,
    var(--blue) -2.47%,
    var(--aqua) 102.78%
  );

  --aqua-emerald-gradient: linear-gradient(
    153.07deg,
    var(--aqua) -2.47%,
    var(--emerald) 102.78%
  );

  --emerald-yellow-gradient: linear-gradient(
    153.07deg,
    var(--emerald) -2.47%,
    var(--yellow) 102.78%
  );

  --font-primary: "Inter", sans-serif;
  --font-secondary: "Space Grotesk", sans-serif;
  --font-mono: "Fira Code", monospace;
}

.theme-light {
  --dark-text: #201d1e;
  --light-text: #3e3e3e;
  --dark-background: #dedede;
  --light-background: #f1f1f1;
  --primary-color: #e175f7;
  --primary-color-shadow: #fa78fa;
  --secondary-color: #8e59e2;
  --secondary-color-shadow: #6b40b0;

  --button-border: #5E4B56;
  --pink: #ff7dcd;
  --blue: #77c4ff;
  --violet: #CC6EF2;
  --white: #fff;
  
  --blue-pink-gradient: linear-gradient(
    153.07deg,
    var(--blue) -2.47%,
    var(--pink) 102.78%
  );

  --pink-blue-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--blue) 102.78%
  );

  --blue-violet-gradient: linear-gradient(
    153.07deg,
    var(--blue) -2.47%,
    var(--violet) 102.78%
  );

  --pink-violet-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--violet) 102.78%
  );
}

.theme-dark {
  --dark-text: #dedede;
  --light-text: #ababab;  
  --dark-background: #1b1b1b;
  --light-background: #323232;
  --primary-color: #81369e;
  --primary-color-shadow: #442053;
  --secondary-color: #d462bf;
  --secondary-color-shadow: #b84aa3;
  --button-border: #B8DBD9;
  --pink: #862a75;
  --blue: #113369;
  --violet: #5c0f7a;
  --white: #fff;
  
  --blue-pink-gradient: linear-gradient(
    153.07deg,
    var(--blue) -2.47%,
    var(--pink) 102.78%
  );

  --pink-blue-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--blue) 102.78%
  );

  --blue-violet-gradient: linear-gradient(
    153.07deg,
    var(--blue) -2.47%,
    var(--violet) 102.78%
  );

  --pink-violet-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--violet) 102.78%
  );
}
